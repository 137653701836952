var input = $('#custom-input');
var btn = $('#custom-donation-btn');

input.on("input", function() {
  var link = "https://donner.miedepain.asso.fr/235/?reserved_code_media=WD21-1_LP&amount=";
  var sum = this.value;
  sum = sum.substring(0, sum.length - 1);
  btn.attr('href', link + (sum * 100));
});

function computeDedutection() {
  var x = document.getElementById("custom-input").value;
  x = (parseInt(x) * 0.25);
  if (isNaN(x))
    document.getElementsByClassName("input-custom-donation-reduction")[0].innerHTML = "Soit ... € après déduction fiscale";
  else 
    document.getElementsByClassName("input-custom-donation-reduction")[0].innerHTML = "Soit " + x + " € après déduction fiscale";
}

function addCurrency() {
  var input = document.getElementById("custom-input");
  var str = input.value;
  if (!str.includes("€")) {
    str = "€" + str;
  }
  input.value = str;
  $("#custom-input").focus();
  $("#custom-input").get(0).setSelectionRange(0,0);
}