$(window).on("scroll", function() {
  if (window.pageYOffset > $('#caption').position().top) {
    $('.back-to-website').css({
      opacity: 0
    })
  } else {
    $('.back-to-website').css({
      opacity: 1
    })
  }
});