var doted = {
  "current": "dist/asset/img/dot-fill.svg",
  "other": "dist/asset/img/dot.svg"
}

var totalItems = $('.item').length;
var currentIndex = $('div.active').index();

$('#sliderCarousel').on('slid.bs.carousel', function() {
  currentIndex = $('div.active').index();
  $('.num').html(''+currentIndex+'/'+totalItems+'');
  $('.dot').each(function() {
    $(this).attr('src', doted['other'])
  })
  $('.caption-container').each(function() {
    $(this).hide()
  })
  $('#dot-' + currentIndex).attr('src', doted['current'])
  $('#caption-' + currentIndex).show();
});

$('.dot').each(function() {
  $(this).click(function() {
    var number = this.dataset['n'];
    $('#sliderCarousel').carousel(parseInt(number))
  })
})