var OBJECTIVE = 140000;
var progressBar = undefined;

function ProgressBar() {
  this.state = {
    angle: 0,
    size: 0,
    stop: false,
    number: 0,
    i: 0
  };
  
  this.counterStatus = document.getElementById("counterStatus");
  this.numberElement = document.getElementById("progressValue");
  this.element = document.getElementById("progressStatus");
  this.interval = undefined;
  
  this.startProgression = function() {
    this.interval = setInterval((function() {
      if (this.state.number === 0) {
        this.render(0, 0)
        clearInterval(this.interval);
        return;
      }
      if (this.state.i >= this.state.number)
      clearInterval(this.interval);
      else {
        if ((this.state.number - this.state.i) > 10000)
          this.state.i += 1000;
        else if ((this.state.number - this.state.i) > 2000)
          this.state.i += 100;
        else if ((this.state.number - this.state.i) > 200)
          this.state.i += 10;
        else
          this.state.i++;
        this.state.size = (this.state.i / OBJECTIVE) * 100;
        if (this.state.size > 100)
        this.state.size = 100;
        else if (this.state.size < 0)
        this.state.size = 0;
      }
      this.render(this.state.size, this.state.i);
    }).bind(this), 10);
  };
  
  this.stop = function(value) {
    this.state.stop = true;
    this.state.number = value;
  };
  
  this.render = function(size, number, angle) {
    if (size !== null)
      this.element.style.width = size + "%";
    if (number !== null) {
      this.numberElement.textContent = numberSpaces(number) + " repas" + " acheté" + ((number === 0 || number === 1) ? "" : "s");
      if (number >= OBJECTIVE) {
        this.element.classList.add('complete');
      }
      if (number == 0) {
        this.element.classList.add('progress-empty');
      } else {
        this.element.classList.remove('progress-empty')
      }
    }
    if (number !== null){
      this.counterStatus.style.display = "none";
      this.element.classList.remove('loading');
    }
  };
  
  this.startAnimation = function () {
    this.interval = setInterval((function() {
      if (this.state.stop === true && this.state.angle === 0) {
        clearInterval(this.interval);
        this.startProgression();
      } else {
        this.state.angle++;
        if (this.state.angle >= 360)
        this.state.angle = 0;
      }
      this.render(null, null, this.state.angle);
    }).bind(this), 5);
  };
}

function getNbrDuvetBought(progressBar) {
  let res = 0;
  let obj;
  
  $.ajax({
    url: "https://mdp-fa-2021.herokuapp.com/",
    type: "GET",
    dataType: "text",
    success: function(response) {
      obj = JSON.parse(response);
      if (obj.error === true) {
        console.log("Error: ", obj.message);
      }
      for (let i = 0; i < obj.products.length; i++) {
        let tmp = parseInt(obj.products[i]);
        if (tmp)
        res += tmp;
      }
      res = Math.trunc(res / (4 * 100)); // (100 = 1€)
      console.log(res);
      res = res - 371201;
      progressBar.stop(res);
    }
  });
}

function handleCounter() {
  progressBar = new ProgressBar();
  progressBar.startAnimation();
  getNbrDuvetBought(progressBar);
}

$(document).ready(handleCounter);

function numberSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}